/* ChatPage.css */
.chat-message {
  opacity: 0;
  animation: fadeIn 0.5s forwards;
  font-size: 16px;
  margin: 10px 0;
  padding: 10px;
  border-radius: 10px;
  /* max-width: 75%; */
  word-wrap: break-word;
  border-color: #d9d9d9;
}

.chat-message.user {
  align-self: flex-end;
  background-color: #e6f7ff;
}

.chat-message.bot {
  align-self: flex-start;
  background-color: #fff;
  margin: 0 auto;
}

.custom-textarea {
  font-size: 16px;
  border-radius: 15px;
  padding: 10px 15px;
  width: 100%;
  border: 1px solid #d9d9d9;
  outline: none;
  transition: all 0.3s ease;
}

.custom-textarea:focus {
  border-color: #40a9ff;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

.ant-space {
  width: 100%;
  justify-content: center;
  align-items: center;
}

.ant-btn-primary {
  margin-left: 10px;
  /* border-radius: 20px; */
}

.user-message {
  margin-left: auto;
  /* width: 200px; */
  max-width: 50% !important;
}
.bot-message {
  width: 90%;
  align-self: flex-start;
}
